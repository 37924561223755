import OurLocations from './ourLocations/OurLocations';
import classes from './Home.module.scss';
import Company from './company/Company';
import Contacts from './contacts/Contacts';
import Footer from './footer/Footer';
import Hero from './hero/Hero';
import Mountains from './mountains/Mountains';
import Partners from './partners/Partners';
import Services from './services/Services';

const Home = () => {
    return (
        <div className={classes.bodyContainer}>
            <div className={classes.routesContainer}>
                <Hero />
                <Mountains />
                <Services />
                <Company />
                <OurLocations />
                <Contacts />
                <Partners />
                <div className={classes.footer}>
                    <Footer />
                </div>
            </div>
        </div>
    );
};

export default Home;
