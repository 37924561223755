import { useNavigate } from 'react-router-dom';
import classes from './Icons.module.scss';
import { useTranslation } from 'react-i18next';

export const ArrowBackward = () => {
    const { i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="29.172"
            viewBox="0 0 15.414 29.172"
            className={classes.arrowBackward}
            onClick={() => navigate(`/${i18n.language}`)}
        >
            <path
                stroke="black"
                strokeWidth="3"
                id="arrow_backward_48x48"
                d="M126.646,57.944l1.061,1.061-13.6,13.6,13.447,13.447-1.06,1.061L112.513,73.138a.75.75,0,0,1,0-1.061Z"
                transform="translate(-112.293 -57.944)"
            />
        </svg>
    );
};
