import classes from './Icons.module.scss';

export const Facebook = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={classes.facebook}>
            <path
                className={classes.facebookPath}
                id="social_facebook"
                d="M7.909,5A2.929,2.929,0,0,0,5,7.909V34.091A2.929,2.929,0,0,0,7.909,37H34.091A2.929,2.929,0,0,0,37,34.091V7.909A2.929,2.929,0,0,0,34.091,5Zm0,2.909H34.091V34.091H26.545V24.273h3.773l.545-4.364H26.545V17.091c0-1.273.312-2.136,2.136-2.136h2.364V11c-.4-.051-1.8-.136-3.409-.136-3.352,0-5.636,2.017-5.636,5.773v3.273H18.182v4.364H22v9.818H7.909Z"
                transform="translate(-5 -5)"
                fill="#96acab"
            />
        </svg>
    );
};

