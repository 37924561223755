import { useEffect, useState } from 'react';
import classes from './Navbar.module.scss';
import logo from '../../assets/images/logo.png';
import LangMenu from 'components/langMenu/LangMenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { availableLanguages } from 'i18n';

const Navbar = () => {
    const location = useLocation();
    const { i18n } = useTranslation();
    const [isHome, setIsHome] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!i18n) return;
        const path = document.location.pathname.concat('');
        const split = path.split('/');
        const loc = split[split.length - 1];
        setTimeout(() => {
            i18n.changeLanguage(loc);
        }, 10);
    }, [i18n]);

    useEffect(() => {
        const path = document.location.pathname.concat('');
        const split = path.split('/');
        const loc = split[split.length - 1];
        const lastSlashIndex = path.lastIndexOf('/');
        const locDef = path.substring(0, lastSlashIndex + 1);
        if (!availableLanguages.includes(loc)) {
            setTimeout(() => {
                navigate(`${locDef}en`);
            }, 10);
        }
    }, [navigate]);

    useEffect(() => {
        setIsHome(location.pathname === '/en' || location.pathname === '/gr');
    }, [i18n.language, location]);

    return (
        <>
            {isHome ? (
                <nav className={classes.container}>
                    <img src={logo} alt="logo" />
                    <LangMenu />
                </nav>
            ) : null}
        </>
    );
};

export default Navbar;
