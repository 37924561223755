import { ClickAwayListener, Tooltip } from '@mui/material';
import eng from 'assets/images/flag_eng.png';
import grk from 'assets/images/flag_grk.png';
import classes from './LangMenu.module.scss';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const LangMenu = () => {
    const { t, i18n } = useTranslation('langMenu');
    const onChangeLanguage = useCallback((language: string) => i18n.changeLanguage(language), [i18n]);
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => setOpen(false);
    const handleTooltipOpen = () => setOpen(prev => !prev);
    const navigate = useNavigate();
    const path = document.location.pathname.concat('');
    const loc = path.slice(0, -2);

    const langListElement = () => {
        return (
            <div className={classes.langButtonContainer}>
                <div
                    onClick={() => {
                        onChangeLanguage('gr');
                        navigate(`${loc}gr`);
                    }}
                >
                    <img src={grk} alt="flag_grk" />
                    <input type="radio" id="grk" name="lang" value="gr" />
                    <label htmlFor="grk" className={i18n.language.includes('gr') ? 'blue' : 'black'}>
                        <p>{t('gr')}</p>
                    </label>
                </div>
                <div
                    onClick={() => {
                        onChangeLanguage('en');
                        navigate(`${loc}en`);
                    }}
                >
                    <img src={eng} alt="flag_eng" />
                    <input type="radio" id="eng" name="lang" value="en" />
                    <label htmlFor="eng" className={i18n.language !== 'gr' ? 'blue' : 'black'}>
                        <p>{t('en')}</p>
                    </label>
                </div>
            </div>
        );
    };

    return (
        <div className={classes.container}>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div>
                    <Tooltip
                        slotProps={{
                            tooltip: {
                                sx: {
                                    backgroundColor: 'white',
                                    border: '1px solid #96acab',
                                    borderRadius: 0,
                                    boxShadow: '5px 5px 20px  grey'
                                }
                            },
                            arrow: {
                                sx: {
                                    color: 'white',
                                    '&::before': {
                                        border: '1px solid #96acab',
                                        boxSizing: 'border-box'
                                    }
                                }
                            }
                        }}
                        PopperProps={{
                            disablePortal: true
                        }}
                        arrow
                        onClose={handleTooltipClose}
                        open={open}
                        disableFocusListener
                        disableHoverListener
                        disableTouchListener
                        title={langListElement()}
                    >
                        <button className={classes.button} onClick={handleTooltipOpen}>
                            <img className={classes.flag} src={i18n.language.includes('gr') ? grk : eng} alt="lang_flag" />
                            <p>{i18n.language.includes('gr') ? 'Ελληνικά' : 'English'}</p>
                        </button>
                    </Tooltip>
                </div>
            </ClickAwayListener>
        </div>
    );
};

export default LangMenu;
