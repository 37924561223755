import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './OurLocations.module.scss';
import { RightArrow } from 'assets/icons/RightArrow';

const OurLocations: FC = () => {
    const { t } = useTranslation('locations');

    return (
        <div className={classes.container}>
            <h3>{t('title')}</h3>
            <div className={classes.content}>
                <div className={classes.image}></div>
                <div className={classes.links}>
                    <div className={classes.references}>
                        <div className={classes.countryReference}>
                            <div className={classes.title}>
                                <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                                    <h3>{t('italy')}</h3>
                                </a>
                                <RightArrow />
                            </div>
                            <div className={classes.cities}>
                                <p>{t('pg')}</p>
                                <p>{t('rm')}</p>
                                <p>{t('to')}</p>
                                <p>{t('bo')}</p>
                                <p>{t('an')}</p>
                            </div>
                        </div>
                        <div className={classes.countryReference}>
                            <div className={classes.title}>
                                <a target="_blank" rel="noreferrer" href={'https://www.de.eagleprojects.it'}>
                                    <h3>{t('germany')}</h3>
                                </a>
                                <RightArrow />
                            </div>
                            <div className={classes.cities}>
                                <p>{t('munch')}</p>
                            </div>
                        </div>
                    </div>
                    <div className={classes.references}>
                        <div className={classes.countryReference}>
                            <div className={classes.title}>
                                <h3>{t('greece')}</h3>
                            </div>
                            <div className={classes.cities}>
                                <p>{t('athens')}</p>
                            </div>
                        </div>
                        <div className={classes.countryReference}>
                            <div className={classes.title}>
                                <h3>{t('tunisia')}</h3>
                            </div>
                            <div className={classes.cities}>
                                <p>{t('tunisi')}</p>
                            </div>
                        </div>
                        <div className={classes.countryReference}>
                            <div className={classes.title}>
                                <a target="_blank" rel="noreferrer" href={'https://agile7.com.br/'}>
                                    <h3>{t('brazil')}</h3>
                                </a>
                                <RightArrow />
                            </div>
                            <div className={classes.cities}>
                                <p>{t('sanpaolo')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurLocations;
