import classes from './Icons.module.scss';

export const Linkedin = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" className={classes.linkedin}>
            <path
                className={classes.linkedinPath}
                id="icons8-linkedin"
                d="M8.636,5A3.662,3.662,0,0,0,5,8.636V33.364A3.662,3.662,0,0,0,8.636,37H33.364A3.662,3.662,0,0,0,37,33.364V8.636A3.662,3.662,0,0,0,33.364,5Zm0,2.909H33.364a.711.711,0,0,1,.727.727V33.364a.711.711,0,0,1-.727.727H8.636a.711.711,0,0,1-.727-.727V8.636A.711.711,0,0,1,8.636,7.909Zm4.273,2.5a2.5,2.5,0,1,0,2.5,2.5A2.5,2.5,0,0,0,12.909,10.409Zm13.136,6.636A4.486,4.486,0,0,0,22,19.273h-.091V17.364H17.818V31.182h4.273V24.364c0-1.8.358-3.545,2.591-3.545,2.2,0,2.227,2.034,2.227,3.636v6.727h4.273V23.591C31.182,19.875,30.392,17.046,26.046,17.046Zm-15.227.318V31.182h4.318V17.364Z"
                transform="translate(-5 -5)"
                fill="#96acab"
            />
        </svg>
    );
};

