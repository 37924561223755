import { FC, useEffect, useState } from 'react';
import classes from './CookiesBanner.module.scss';
import { Cookies } from 'assets/icons/Cookies';
import { useTranslation } from 'react-i18next';

const CookiesBanner: FC = () => {
    const [isVisible, setIsVisible] = useState(true);
    const { t } = useTranslation('cookies');

    useEffect(() => {
        const bannerDisplayed = localStorage.getItem('cookieBannerDisplayed');
        if (bannerDisplayed) {
            setIsVisible(false);
        }
    }, []);

    const handleClose = () => {
        setIsVisible(false);
        localStorage.setItem('cookieBannerDisplayed', 'true');
    };

    return (
        <>
            {isVisible && (
                <div className={classes.container}>
                    <div className={classes.explication}>
                        <Cookies />
                        <div>
                            <span className="white">{t('p_1')}</span>
                            <span className="white">
                                {t('p_2')} <b>{t('p_3')}</b>
                            </span>
                        </div>
                    </div>
                    <button onClick={handleClose}>OK</button>
                </div>
            )}
        </>
    );
};

export default CookiesBanner;
