import Footer from 'views/home/footer/Footer';
import classes from './Privacy.module.scss';
import { ArrowBackward } from 'assets/icons/ArrowBackward';

const Privacy = () => {
    return (
        <div className={classes.bodyContainer}>
            <div className={classes.privacyContainer}>
                <div className={classes.content}>
                    <h1>
                        <ArrowBackward />
                        PRIVACY POLICY
                    </h1>
                    <div className={classes.subtitle}>
                        <h5 className="blue">PRIVACY AND COOKIE POLICY</h5>
                    </div>
                    <p>
                        In accordance with the regulations on the protection of personal data (Legislative Decree no. 196/03 and
                        subsequent amendments and, starting from May 25, 2018, the General Data Protection Regulation no.
                        679/2016/EU), Eagleprojects S.P.A. – with registered office at Strada San Galigano 12, Perugia, VAT number
                        003489760540, Tel. 075 7750322, e-mail <a href="mailto:info@eagleprojects.it">info@eagleprojects.it </a>
                        (hereinafter referred to as "Eagle"), as the Data Controller, is required to provide information regarding
                        its cookie policy to disclose the procedures followed for the collection, through cookies and/or other
                        tracking technologies, of information provided by users.
                    </p>

                    <div className={classes.subtitle}>
                        <h5 className="blue">CONSENT</h5>
                    </div>
                    <p>
                        By browsing the gisfo.com website (hereinafter "Site"), you consent to the processing of data as described
                        below, and in accordance with the following policy. It is important to inform you from the outset that
                        this site will not process your "sensitive data."
                    </p>
                    <div className={classes.subtitle}>
                        <h5 className="blue">WHAT ARE COOKIES?</h5>
                    </div>
                    <p>
                        Websites typically do not have the functionality to "remember" users who visit them or recognize their
                        preferences or tastes. Cookies are small text files used by websites to recognize you as a unique user and
                        are sent from the website to the device used (PC, smartphone, notebook, etc.) to be stored and then reused
                        in the next visit.
                    </p>
                    <p>
                        Cookies are not all the same, and it is important to distinguish their types and functions to always
                        participate in informed and conscious browsing.
                    </p>
                    <p>
                        A fundamental distinction is between temporary (session) cookies that end their function when the session
                        is closed, and persistent cookies, which, at the end of the navigation, are not immediately removed but
                        remain on the used device. Their function is to facilitate user navigation.
                    </p>
                    <p>Both of these types can, in turn, be "first-party" or "third-party."</p>
                    <p>
                        The former are cookies from the site being visited, and the latter are cookies created by different sites,
                        third parties, who are responsible for the management of these cookies.
                    </p>
                    <p>
                        The Site uses Umami cookies and processes information only in aggregate format for statistical purposes;
                        click&nbsp;
                        <a className="blue" target="_blank" rel="noreferrer" href="https://umami.is/docs/faq">
                            here
                        </a>
                        &nbsp; for more information on this.
                    </p>
                    <p>
                        Another distinction is between "technical" cookies and "profiling" cookies. The former, as specified in
                        Article 122 of the GDPR, are "solely aimed at carrying out the transmission of a communication over an
                        electronic communications network, or to the extent strictly necessary for the provider of an information
                        society service explicitly requested by the contracting party or user to provide this service." On the
                        other hand, profiling cookies are intended to classify users based on profiles to send targeted
                        advertisements based on interests expressed during navigation.
                    </p>
                    <p>The Site does not use profiling cookies. </p>
                    <div className={classes.subtitle}>
                        <h5 className="blue">DELETION OF COOKIES</h5>
                    </div>
                    <p>
                        Initially, most used browsers are set to accept the use of cookies. You can change these settings directly
                        from the used browser by accessing the links below:
                    </p>
                    <ul>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                            >
                                <p className="blue">Mozilla Firefox</p>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://support.google.com/accounts/answer/61416?hl=en">
                                <p className="blue">Google Chrome</p>
                            </a>
                        </li>

                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://support.microsoft.com/it-it/windows/eliminare-e-gestire-i-cookie-168dab11-0753-043d-7c16-ede5947fc64d"
                            >
                                <p className="blue">Internet Explorer</p>
                            </a>
                        </li>
                        <li>
                            <a
                                target="_blank"
                                rel="noreferrer"
                                href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
                            >
                                <p className="blue">Opera</p>
                            </a>
                        </li>
                        <li>
                            <a target="_blank" rel="noreferrer" href="https://support.apple.com/it-it/HT201265">
                                <p className="blue">Safari</p>
                            </a>
                        </li>
                    </ul>
                    <div className={classes.subtitle}>
                        <h5 className="blue">DATA CONTROLLER</h5>
                    </div>
                    <p>
                        This Policy applies exclusively to browsing the
                        <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                            &nbsp;www.eagleprojects.it&nbsp;
                        </a>
                        website, which has been duly registered by Eagleprojects SPA. The Data Controller is Eagleprojects SPA,
                        represented by its legal representative. For additional information regarding the Site and privacy, you
                        can write to the e-mail address <a href="mailto: info@eagleprojects.it"> info@eagleprojects.it</a>. By
                        writing to the aforementioned address, you agree that Eagleprojects holds your e-mail address and that it
                        will be used to be contacted to receive the requested answers. It will not be used for any marketing
                        activities or included in any newsletters.
                    </p>
                    <div className={classes.subtitle}>
                        <h5 className="blue">DATA COLLECTED, PURPOSES AND PROCESSING METHODS</h5>
                    </div>
                    <p>
                        The Site, through dedicated applications for its operation, may collect some of your data (implicit in the
                        use of Internet communication protocols), which, in any case, are not directly associated with you. Among
                        these data are included, among others, the IP address, domain names, the type of browser used for the
                        connection, request times, numerical codes indicating the response received from the server, etc.
                    </p>
                    <p>
                        The mentioned data do not allow, in any way, to reveal your identity and are therefore anonymous. As
                        already clarified above, the data will not be used for profiling, marketing, or other activities. Their
                        collection is aimed solely at improving your browsing experience, will be anonymized, and collected in
                        aggregate form for statistical purposes. The anonymity of the IP address could be suspended if it is
                        useful to prevent an attempt to damage the Site or the commission of other crimes. If you do not agree, we
                        recommend suspending browsing on this Site.
                    </p>
                    <div className={classes.subtitle}>
                        <h5 className="blue">DATA VOLUNTARILY PROVIDED BY THE USER</h5>
                    </div>
                    <p>
                        The optional, explicit, and voluntary submission of data (Name, Surname, e-mail, telephone number) through
                        the "Contact us" and "Work with us" pages on this site entails, by its very nature, the subsequent
                        acquisition of such data. The legal bases for processing are, therefore, the Data Controller's legitimate
                        interest in responding to incoming communications or the need to fulfill requests made by the data
                        subject. The data will be kept for the time necessary to satisfy any requests from the sender or issues
                        submitted to the Data Controller and, in any case, for the time required by specific legal provisions. In
                        this hypothesis, the data provided through the "Contact us" and "Work with us" forms are included. In such
                        cases, the provided data and any CVs will be used for the purpose of applying for open positions or
                        spontaneous applications and subsequently to contact the interested parties based on the contact details.
                        In no case will this information be used for purposes other than those described above.
                    </p>
                    <div className={classes.subtitle}>
                        <h5 className="blue">YOUR RIGHTS</h5>
                    </div>
                    <p>
                        As provided and stipulated in Article 7 of the GDPR, as a data subject, you are guaranteed the following
                        rights:
                    </p>
                    <p>
                        1. The data subject has the right to obtain confirmation of the existence or not of personal data
                        concerning him, even if not yet recorded, and their communication in an intelligible form.
                    </p>
                    <p>2. The data subject has the right to obtain the indication:</p>
                    <ul>
                        <li>
                            <p>a&#41; of the origin of personal data;</p>
                        </li>
                        <li>
                            <p>b&#41; of the purposes and methods of processing;</p>
                        </li>
                        <li>
                            <p>
                                c&#41; of the logic applied in case of processing carried out with the aid of electronic
                                instruments;;
                            </p>
                        </li>
                        <li>
                            <p>
                                d&#41; of the identification details of the controller, processors, and the designated
                                representative under Article 5, paragraph 2;
                            </p>
                        </li>
                        <li>
                            <p>
                                e&#41; of the subjects or categories of subjects to whom the personal data may be communicated or
                                who may become aware of it as a designated representative in the territory of the State,
                                processors, or persons in charge.
                            </p>
                        </li>
                    </ul>
                    <p>3. The data subject has the right to obtain:</p>
                    <ul>
                        <li>
                            <p>a&#41; updating, rectification, or, when interested, integration of data;</p>
                        </li>
                        <li>
                            <p>
                                b&#41; the cancellation, transformation into anonymous form, or blocking of data processed in
                                violation of the law, including those for which storage is not necessary in relation to the
                                purposes for which the data were collected or subsequently processed;
                            </p>
                        </li>
                        <li>
                            <p>
                                c&#41; certification that the operations referred to in letters a&#41; and b&#41; have been
                                brought to the attention, also with regard to their content, of those to whom the data have been
                                communicated or disseminated, except in the case where such fulfillment proves impossible or
                                involves a use of means manifestly disproportionate to the protected right.
                            </p>
                        </li>
                    </ul>
                    <p>4. The data subject has the right to object, in whole or in part:</p>
                    <ul>
                        <li>
                            <p>
                                a&#41; for legitimate reasons to the processing of personal data concerning him, even if pertinent
                                to the purpose of the collection;
                            </p>
                        </li>
                        <li>
                            <p>
                                b&#41; to the processing of personal data concerning him for the purpose of sending advertising
                                materials or direct selling or for carrying out market research or commercial communication.
                            </p>
                        </li>
                    </ul>
                </div>
                <div className={classes.footerContainer}>
                    <Footer />
                    <p>© 2023 Eagleprojects S.p.A. | All rights reserved</p>
                </div>
            </div>
        </div>
    );
};

export default Privacy;
