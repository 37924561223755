import classes from './Icons.module.scss';

export const Youtube = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 40 30" className={classes.youtube}>
            <path
                className={classes.youtubePath}
                id="social_youtube"
                d="M23,6A101.365,101.365,0,0,0,8.962,7.031a6.031,6.031,0,0,0-5.144,4.781A49.837,49.837,0,0,0,3,21a49.837,49.837,0,0,0,.817,9.188,6.024,6.024,0,0,0,5.144,4.781A101.911,101.911,0,0,0,23,36a101.912,101.912,0,0,0,14.038-1.031,6.024,6.024,0,0,0,5.144-4.781A50,50,0,0,0,43,21a49.472,49.472,0,0,0-.817-9.187,6.031,6.031,0,0,0-5.144-4.781A101.364,101.364,0,0,0,23,6Zm0,3a99.55,99.55,0,0,1,13.606.984,2.992,2.992,0,0,1,2.548,2.391A46.718,46.718,0,0,1,39.923,21a46.771,46.771,0,0,1-.769,8.625,2.978,2.978,0,0,1-2.548,2.391A100.205,100.205,0,0,1,23,33a100.123,100.123,0,0,1-13.606-.984,2.985,2.985,0,0,1-2.548-2.391A46.667,46.667,0,0,1,6.077,21a46.614,46.614,0,0,1,.769-8.625A2.985,2.985,0,0,1,9.394,9.984,99.468,99.468,0,0,1,23,9Zm-4.615,3.422V29.578l2.308-1.266,10.769-6L33.769,21l-2.308-1.312-10.769-6Zm3.077,5.156L27.567,21l-6.106,3.422Z"
                transform="translate(-3 -6)"
                fill="#96acab"
            />
        </svg>
    );
};

