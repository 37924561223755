import classes from './Hero.module.scss';
import { useTranslation } from 'react-i18next';

const Hero = () => {
    const { t } = useTranslation('hero');

    return (
        <section className={classes.container}>
            <div className={classes.wrapper}>
                <h1>{t('title')}</h1>
                <h5>{t('subtitle')}</h5>
            </div>
        </section>
    );
};

export default Hero;

