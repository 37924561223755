import { ArrowBackward } from 'assets/icons/ArrowBackward';
import classes from './Disclaimer.module.scss';
import Footer from 'views/home/footer/Footer';

const Disclaimer = () => {
    return (
        <div className={classes.bodyContainer}>
            <div className={classes.disclaimerContainer}>
                <div className={classes.content}>
                    <h1>
                        <ArrowBackward />
                        DISCLAIMER
                    </h1>
                    <div className={classes.subtitle}>
                        <h5 className="blue">NOTICE PURSUANT TO ARTICLE 14 OF EUROPEAN REGULATION 679/2016</h5>
                    </div>
                    <p>For Technological Surveys</p>
                    <p>
                        In accordance with Article 14 of the European Regulation (EU) 2016/79 (hereinafter GDPR), and in relation
                        to personal data that Eagleprojects will acquire in the course of surveys and design activities for
                        infrastructures, the following information is provided:
                    </p>
                    <p>
                        Data Controller and Data Protection Officer (DPO): The Data Controller is Eagleprojects SpA., with
                        operational headquarters at Strada San Galigano 12, Perugia. The Data Controller can be contacted via PEC
                        eagleprojects@pec.it or by registered letter to the address Strada San Galigano 12, Perugia. To contact
                        the corporate DPO, you can send an email to privacy@eagleprojects.it or a registered letter to the address
                        of the aforementioned operational headquarters.
                    </p>
                    <p>
                        Purpose and legal basis of the processing: The processing is aimed at the correct and complete execution
                        of the professional assignment received by Eagleprojects, consisting in the design of infrastructures for
                        the realization of infrastructures, for which survey activities are necessary using instruments such as
                        laserscanners and/or drones with 360° cameras. Eagleprojects SpA. processes personal data lawfully when
                        essential for the pursuit of its legitimate interests, as per Article 6, paragraph 1, letter f&#41;, where
                        the processing is necessary for the execution of the specified activity, or necessary to comply with a
                        legal obligation.
                    </p>
                    <p>
                        Type of data, minimization, and retention: The data that Eagleprojects will acquire consists of images
                        collected by laserscanners and/or drones. The images will be captured by a telelaser device positioned
                        above Eagleprojects' vehicles or by the cameras equipped on the drones during flight. The Controller's
                        interest lies in topographical surveys of the surrounding environment, constituted not by images but by
                        point clouds that can be processed. If faces of individuals, license plate numbers, civic numbers, or any
                        data that could allow the identification of the data subject are captured, they will be obscured through
                        an algorithm prepared for this purpose. The data will be stored, in unobscured format, only for the time
                        necessary for their obscuring; the data will be processed for the purposes mentioned above and
                        subsequently delivered to the Client. Later, they will be stored at the Data Controller's headquarters
                        only in anonymized form. Eagleprojects will not publish the acquired images on any website unless they do
                        not contain information that could lead to the identification of the data subjects.
                    </p>
                    <p>
                        Data disclosure: The acquired data will be processed at the Controller's premises by authorized
                        collaborators and subsequently transmitted to the Client. In case of explicit requests from judicial or
                        administrative authorities, they will be communicated to fulfill legal obligations.
                    </p>
                    <p>
                        Profiling and Data Dissemination: The acquired data are not subject to dissemination or any fully
                        automated decision-making process, nor are they processed for profiling purposes.
                    </p>
                    <p>Rights of the data subjects: Data subjects are granted the following rights:</p>
                    <p>
                        1&#41; Ask the Data Controller for access to their personal data and information related to them,
                        specifying the date and time of the survey; rectification of inaccurate data or integration of incomplete
                        ones; deletion of personal data concerning them;
                    </p>
                    <p>
                        2&#41; Request Eagleprojects for personal data in a structured, commonly used, and machine-readable
                        format, also for the purpose of transmitting the data to another data controller (the right to data
                        portability);
                    </p>
                    <p>3&#41; Object at any time to the processing of their personal data; </p>
                    <p>
                        4&#41; Revoke consent at any time, limited to cases where the processing is based on their consent for one
                        or more specific purposes and concerns common personal data or particular categories of data. Processing
                        based on consent carried out before the revocation remains lawful;
                    </p>
                    <p>
                        5&#41; Lodge a complaint with a supervisory authority (Privacy Guarantor Authority www.garanteprivacy.it).
                    </p>
                    <p>For more information, please visit the website www.eagleprojects.it.</p>
                </div>
                <div className={classes.footerContainer}>
                    <Footer />
                    <p>© 2023 Eagleprojects S.p.A. | All rights reserved</p>
                </div>
            </div>
        </div>
    );
};

export default Disclaimer;
