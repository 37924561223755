import React from 'react';
import classes from './Mountains.module.scss';
import { useTranslation } from 'react-i18next';

const Mountains = () => {
    const { t } = useTranslation('intro');

    return (
        <section className={classes.container}>
            <div className={classes.containerOne}>
                <div className={`${classes.titleContainer} blue`}>
                    <h2>{t('title1')}</h2>
                    <h2>{t('title2')}</h2>
                    <h2>{t('title3')}</h2>
                </div>
                <div className={classes.parContainer}>
                    <p>{t('par1')}</p>
                    <p>{t('par2')}</p>
                    <p>{t('par3')}</p>
                    <p>{t('par4')}</p>
                </div>
            </div>
            <div className={classes.containerTwo}></div>
        </section>
    );
};

export default Mountains;

