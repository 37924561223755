export const RightArrow = () => {
    return (
        <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_768_1071)">
                <mask id="mask0_768_1071" maskUnits="userSpaceOnUse" x="3" y="4" width="26" height="26">
                    <path d="M28.6324 4.11938H3.36816V29.7194H28.6324V4.11938Z" fill="white" />
                </mask>
                <g mask="url(#mask0_768_1071)">
                    <path
                        d="M28.3955 16.3462L16.1687 4.11938L15.0213 5.26575L25.8638 16.1083H3.36816V17.7294H25.8659L15.0234 28.572L16.1687 29.7204L28.3955 17.4884C28.4708 17.4131 28.5305 17.3237 28.5712 17.2254C28.612 17.127 28.6329 17.0216 28.6329 16.9152C28.6329 16.8087 28.612 16.7033 28.5712 16.605C28.5305 16.5066 28.4708 16.4173 28.3955 16.342"
                        fill="#16A3CF"
                    />
                </g>
            </g>
            <defs>
                <clipPath id="clip0_768_1071">
                    <rect width="25.2642" height="25.6" fill="white" transform="translate(3.36816 4.11938)" />
                </clipPath>
            </defs>
        </svg>
    );
};
