import Routing from 'Routing';
import React from 'react';
import 'styles/main.scss';
import Navbar from 'components/navbar/Navbar';
import CookiesBanner from 'components/cookiesBanner/CookiesBanner';

function App() {
    return (
        <>
            <Navbar />
            <Routing />
            <CookiesBanner />
        </>
    );
}

export default App;
