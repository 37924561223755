import Divider from 'components/divider/Divider';
import classes from './Footer.module.scss';
import { Linkedin } from 'assets/icons/Linkedin';
import { Youtube } from 'assets/icons/Youtube';
import { Facebook } from 'assets/icons/Facebook';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { FC } from 'react';

const Footer: FC = () => {
    const { t, i18n } = useTranslation('footer');

    return (
        <footer className={classes.container}>
            <div className={classes.content}>
                <Divider />
                <div className={classes.headerWrapper}>
                    <div className={classes.socialsOnMobile}>
                        <div className={classes.mobileLinkWrap}>
                            <a href="https://www.linkedin.com/company/eagleprojects/" target="_blank" rel="noreferrer">
                                <Linkedin />
                            </a>
                            <a href="https://it-it.facebook.com/eagleprojectssrl/" target="_blank" rel="noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.youtube.com/channel/UCC1D8f0D1PeIVs4Nc0c5RKw" target="_blank" rel="noreferrer">
                                <Youtube />
                            </a>
                        </div>
                        <Divider />
                    </div>
                    <a target="_blank" rel="noreferrer" href={'https://www.eagleprojects.it'}>
                        <h4>eagleprojects s.p.a. </h4>
                    </a>
                    <div className={classes.headerLinks}>
                        <a href="https://eagleprojects.integrity.complylog.com/">
                            <b>{t('legal')}</b>
                        </a>
                        <Link to={`/disclaimer/${i18n.language}`}>
                            <b>{t('disclaimer')}</b>
                        </Link>
                        <Link to={`/privacy/${i18n.language}`}>
                            <b>{t('privacy')}</b>
                        </Link>
                    </div>
                </div>
                <div className={classes.wrapper}>
                    <div className={classes.blockOne}>
                        <div>
                            <p>{t('subtitle1')}</p>
                            <p>
                                <b>{t('par1')}</b>
                            </p>
                        </div>
                        <div>
                            <p>{t('subtitle2')}</p>
                            <p>
                                <b>{t('par2')}</b>
                            </p>
                        </div>
                    </div>
                    <div className={classes.blockTwo}>
                        <div>
                            <p>{t('subtitle3')}</p>
                            <p>
                                <b>163535101001</b>
                            </p>
                        </div>
                        <div>
                            <p>{t('rea')}</p>
                            <p>
                                <b>PG-292954</b>
                            </p>
                        </div>
                    </div>
                    <div className={classes.blockThree}>
                        <div>
                            <p>Email</p>
                            <p>
                                <b>eagleprojects@pec.it</b>
                            </p>
                            <p>
                                <b>info@eagleprojects.it</b>
                            </p>
                        </div>
                        <div>
                            <p>{t('subtitle4')}</p>
                            <p>
                                <b>+30 6974477798</b>
                            </p>
                        </div>
                    </div>
                    <div className={classes.blockFour}>
                        <a href="https://www.linkedin.com/company/eagleprojects/" target="_blank" rel="noreferrer">
                            <Linkedin />
                        </a>
                        <a href="https://it-it.facebook.com/eagleprojectssrl/" target="_blank" rel="noreferrer">
                            <Facebook />
                        </a>
                        <a href="https://www.youtube.com/channel/UCC1D8f0D1PeIVs4Nc0c5RKw" target="_blank" rel="noreferrer">
                            <Youtube />
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
