import axios, { AxiosInstance } from 'axios';
import { UseFormReset } from 'react-hook-form';
import { Application, resetForm } from 'views/home/contacts/Contacts';

class Service {
    api: AxiosInstance;

    constructor() {
        this.api = axios.create({
            baseURL: 'https://form.eagleprojects.cloud/api'
        });
    }
    createApplication = async (data: Application, reset: UseFormReset<Application>) => {
        try {
            delete data.isPrivacyChecked;
            const response = await this.api.post('/v1/hellenica', data);
            resetForm(reset);
            return response;
        } catch (error) {
            console.log(error);
        }
    };
}

const service = new Service();

export default service;
