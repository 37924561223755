import { Switch } from '@mui/material';
import Divider from 'components/divider/Divider';
import { useState } from 'react';
import { SubmitHandler, UseFormReset, useForm } from 'react-hook-form';
import service from 'services/service';
import classes from './Contacts.module.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export type Application = {
    application: {
        name: string;
        surname: string;
        birth: string;
        address: string;
        city: string;
        cap: string;
        email: string;
        phone: string;
        document: string;
        interest: string;
        text: string;
        internship?: string;
        degree?: string;
    };
    isPrivacyChecked?: boolean;
};

export const resetForm = (reset: UseFormReset<Application>) => {
    reset();
};

const Contacts = () => {
    const { t, i18n } = useTranslation('application');
    const [internshipSwitch, setInternshipSwitch] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [pdfData, setPdfData] = useState('');
    const handleInternshipSwitch = () => {
        setInternshipSwitch(prev => !prev);
    };
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isValid }
    } = useForm<Application>({});
    const toBase64 = async (file: Blob): Promise<string> => {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onerror = error => reject(error);
            reader.onload = () => resolve(reader.result as string);
        });
    };
    function formatDate(inputDate: string) {
        const date = new Date(inputDate);
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const onSubmit: SubmitHandler<Application> = data => {
        data.application.document = pdfData;
        if (data.application.internship === undefined) {
            data.application.internship = '';
        }
        if (data.application.degree === undefined) {
            data.application.degree = '';
        }
        data.application.birth = formatDate(data.application.birth);
        service.createApplication(data, reset);
    };

    return (
        <section className={classes.container}>
            <div className={classes.wrap}>
                <h3>{t('title')}</h3>
                <Divider />
                <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <h6>{t('info')}</h6>
                        <div className={classes.infoWrapper}>
                            <input
                                className={`inputText ${errors.application?.name ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('name')}
                                {...register('application.name', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.surname ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('surname')}
                                {...register('application.surname', { required: true })}
                            />
                            <input
                                className={`inputText ${classes.datePicker} ${errors.application?.birth ? 'inputError' : ''}`}
                                type="text"
                                onFocus={e => (e.target.type = 'date')}
                                placeholder={t('date')}
                                {...register('application.birth', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.address ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('address')}
                                {...register('application.address', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.city ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('city')}
                                {...register('application.city', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.cap ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('postcode')}
                                {...register('application.cap', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.email ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('mail')}
                                {...register('application.email', { required: true })}
                            />
                            <input
                                className={`inputText ${errors.application?.phone ? 'inputError' : ''}`}
                                type="text"
                                placeholder={t('phone')}
                                {...register('application.phone', { required: true })}
                            />
                            <div className={`${classes.inputWrapper} ${errors.application?.document ? 'inputError' : ''}`}>
                                <input value={filePath} readOnly className={'inputText'} type="text" placeholder={t('cv')} />
                                <input
                                    id="file-input"
                                    className={classes.uploadFile}
                                    type="file"
                                    {...register('application.document', { required: true })}
                                    onChange={async e => {
                                        const file = e.target.files?.[0];
                                        setFilePath(file?.name || '');
                                        if (file) {
                                            const base64Data = await toBase64(file);
                                            setPdfData(base64Data);
                                        }
                                    }}
                                />
                                <label className={classes.labelUpload} htmlFor="file-input">
                                    <p>
                                        <b>{t('upload')}</b>
                                    </p>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className={classes.positionWrapper}>
                            <h6>{t('position')}</h6>
                            <p>{t('area')}</p>
                            <select
                                {...register('application.interest', { required: true })}
                                className={`${errors.application?.interest ? 'inputError' : ''}`}
                            >
                                <option value="" className={classes.hidden}>
                                    {t('select')}
                                </option>
                                <optgroup label="Technical area">
                                    <option value="hellenica-planning">{t('planning')}</option>
                                    <option value="hellenica-construction">{t('construction')}</option>
                                    <option value="hellenica-geology">{t('geology')}</option>
                                    <option value="hellenica-project">{t('pm')}</option>
                                </optgroup>
                                <optgroup label="ITC area">
                                    <option value="hellenica-business">{t('bm')}</option>
                                    <option value="hellenica-technical">{t('tm')}</option>
                                    <option value="hellenica-design">{t('design')}</option>
                                    <option value="hellenica-development">{t('dev')}</option>
                                    <option value="hellenica-service">{t('service')}</option>
                                    <option value="hellenica-support">{t('support')}</option>
                                </optgroup>
                            </select>
                            <textarea
                                minLength={20}
                                maxLength={1000}
                                className={`${errors.application?.text ? 'inputError' : ''}`}
                                {...register('application.text', {
                                    required: true,
                                    minLength: 20,
                                    maxLength: 1000
                                })}
                                placeholder={t('textarea')}
                            ></textarea>
                        </div>
                    </div>
                    <div className={classes.internshipWrapper}>
                        <h6>{t('phd')}</h6>
                        <div className={classes.switchWrapper}>
                            <div className={classes.switch}>
                                <Switch
                                    className={internshipSwitch ? classes.blue : classes.grey}
                                    id="switch"
                                    size="small"
                                    value={internshipSwitch}
                                    onClick={handleInternshipSwitch}
                                    checked={internshipSwitch}
                                />
                                <label htmlFor="switch">{internshipSwitch ? 'ON' : 'OFF'}</label>
                            </div>
                            <p>{t('phd_application')}</p>
                        </div>
                        <div className={classes.collaborationTypeWrapper}>
                            <p>{t('collab')}</p>
                            <select disabled={!internshipSwitch} {...register('application.internship')}>
                                <option value="hellenica-curricular">{t('curricular')}</option>
                                <option value="hellenica-extra">{t('extra')}</option>
                                <option value="hellenica-dhp">{t('dhp')}</option>
                            </select>
                            <input
                                className={'inputText'}
                                disabled={!internshipSwitch}
                                type="text"
                                placeholder={t('course')}
                                {...register('application.degree')}
                            />
                        </div>
                    </div>
                    <div className={classes.privacyWrapper}>
                        <h6>{t('privacy')}</h6>
                        <div className={classes.checkWrapper}>
                            <input type="checkbox" id="privacy-check" {...register('isPrivacyChecked', { required: true })} />
                            <label htmlFor="privacy-check" className={`${errors.isPrivacyChecked ? 'inputError' : ''}`}>
                                {t('accept')} <Link to={`/privacy/${i18n.language}`}>{t('accept_2')}</Link>
                            </label>
                        </div>
                    </div>
                    <button type="submit" disabled={!isValid} className={classes.sendButton}>
                        <p>
                            <b>{t('send')}</b>
                        </p>
                    </button>
                </form>
            </div>
        </section>
    );
};

export default Contacts;
